html,
body {
    margin: 0px;
    width: 100%;
    height: 100%;
}

#content {
    width: 100%;
    height: 100%;
}

#map {
    position: absolute;
    top: 51px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

#header {
    font-size: 1.3em;
    font-weight: bold;
    font-family: arial, helvetica;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 50px;

    background-image: linear-gradient(141deg, #9fb8ad 0%, #073141 75%);

    color: white;
    line-height: 50px;
    text-align: center;
    border-bottom: 1px solid black;
}

#header a.sources {
    float: left;
    margin-left: 50px;
    color: white;
}

#header a.logo-main {
    float: right;
    margin-right: 25px;
}

#header a.logo-main img {
    height: 50px;
}

#header a.logo-page {
    float: left;
    margin-left: 25px;
}

#header a.logo-page img {
    height: 50px;
}

.logo-watermark {
    height: 2cm;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.link {
    cursor: pointer;
    color: blue;
    text-decoration: none;
}

.link:hover {
    text-decoration: underline;
}

.leaflet-control-layers {
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.4);
    background: #f8f8f9;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    overflow: auto;
    max-height: 300px;
}

.my-label-las {
    position: absolute;
    font-size: 10px;
    color: green;
    font-style: italic;
    font-weight: bolder;
}


.modal-footer {
    display: block;
    text-align: right;
}
  
.modal-footer .topcoat-button-bar {
    clear: both;
}
  
.modal-footer button {
    cursor: pointer;
    margin-left: 8px;
}

.modal-header,
.modal-body,
.modal-footer {
    padding-left: 15px;
    padding-right: 15px;
}